.feature-card-feature-card {
  background-color: var(--dl-color-custom-primary1);
}
.feature-card-icon {
  fill: #ffffff;
}
.feature-card-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card-text {
  color: #ffffff;
  font-weight: 600;
  line-height: 28px;
}
.feature-card-text1 {
  color: #ffffff;
}


@media(max-width: 991px) {
  .feature-card-feature-card {
    background-color: #831010;
  }
  .feature-card-icon {
    fill: #ffffff;
  }
  .feature-card-text {
    color: #ffffff;
  }
  .feature-card-text1 {
    color: #ffffff;
  }
  .feature-card-root-class-name1 {
    background-color: var(--dl-color-custom-primary1);
  }
  .feature-card-root-class-name3 {
    background-color: var(--dl-color-custom-primary1);
  }
}
@media(max-width: 767px) {
  .feature-card-feature-card {
    flex-direction: row;
  }
  .feature-card-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card-feature-card {
    background-color: #831010;
  }
  .feature-card-container {
    flex-direction: column;
  }
}
