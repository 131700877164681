.how-it-works-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #121212;
}
.how-it-works-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.how-it-works-image {
  width: 200px;
  height: 67px;
  object-fit: cover;
}
.how-it-works-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.how-it-works-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.how-it-works-nav12 {
  text-decoration: none;
}
.how-it-works-nav22 {
  margin-left: var(--dl-space-space-unit);
}
.how-it-works-nav42 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.how-it-works-nav52 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.how-it-works-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.how-it-works-login {
  font-family: "Chillax-Regular";
  margin-right: 32px;
  text-decoration: none;
}
.how-it-works-register {
  font-family: "Chillax-Regular";
  text-decoration: none;
  background-color: #db0000;
}
.how-it-works-burger-menu {
  display: none;
}
.how-it-works-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.how-it-works-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
.how-it-works-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.how-it-works-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.how-it-works-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.how-it-works-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.how-it-works-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.how-it-works-nav121 {
  margin-bottom: var(--dl-space-space-unit);
}
.how-it-works-nav221 {
  margin-bottom: var(--dl-space-space-unit);
}
.how-it-works-nav32 {
  margin-bottom: var(--dl-space-space-unit);
}
.how-it-works-nav421 {
  margin-bottom: var(--dl-space-space-unit);
}
.how-it-works-nav521 {
  margin-bottom: var(--dl-space-space-unit);
}
.how-it-works-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.how-it-works-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.how-it-works-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.how-it-works-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.how-it-works-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.how-it-works-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.how-it-works-hero-heading {
  max-width: 800px;
  text-align: center;
}
.how-it-works-steps {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  background-color: #121212;
}
.how-it-works-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.how-it-works-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.how-it-works-step {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.how-it-works-text {
  color: var(--dl-color-custom-primary1);
  font-size: 4rem;
  text-align: center;
  font-weight: 900;
  margin-bottom: -30px;
}
.how-it-works-text02 {
  color: var(--dl-color-gray-white);
}
.how-it-works-text03 {
  color: var(--dl-color-gray-white);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
}
.how-it-works-step1 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.how-it-works-text04 {
  color: var(--dl-color-custom-primary1);
  font-size: 4rem;
  text-align: center;
  font-weight: 900;
  margin-bottom: -30px;
}
.how-it-works-text06 {
  color: var(--dl-color-gray-white);
}
.how-it-works-text07 {
  color: var(--dl-color-gray-900);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
}
.how-it-works-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.how-it-works-step2 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.how-it-works-text10 {
  color: var(--dl-color-custom-primary1);
  font-size: 4rem;
  text-align: center;
  font-weight: 900;
  margin-bottom: -30px;
}
.how-it-works-text12 {
  color: var(--dl-color-gray-white);
}
.how-it-works-text13 {
  color: var(--dl-color-gray-900);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
}
.how-it-works-step3 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.how-it-works-text16 {
  color: var(--dl-color-custom-primary1);
  font-size: 4rem;
  text-align: center;
  font-weight: 900;
  margin-bottom: -30px;
}
.how-it-works-text18 {
  color: var(--dl-color-gray-white);
}
.how-it-works-text19 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
}
.how-it-works-text20 {
  color: var(--dl-color-gray-900);
}
.how-it-works-container5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.how-it-works-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.how-it-works-nav122 {
  text-decoration: none;
}
.how-it-works-nav222 {
  margin-left: var(--dl-space-space-unit);
}
.how-it-works-nav422 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.how-it-works-nav522 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.how-it-works-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.how-it-works-container6 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.how-it-works-icon-group1 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 991px) {
  .how-it-works-hero1 {
    flex-direction: column;
  }
  .how-it-works-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .how-it-works-hero-heading {
    text-align: center;
  }
  .how-it-works-text {
    text-align: center;
  }
  .how-it-works-text02 {
    text-align: center;
  }
  .how-it-works-text04 {
    text-align: center;
  }
  .how-it-works-text06 {
    text-align: center;
  }
  .how-it-works-text10 {
    text-align: center;
  }
  .how-it-works-text12 {
    text-align: center;
  }
  .how-it-works-text16 {
    text-align: center;
  }
  .how-it-works-text18 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .how-it-works-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .how-it-works-desktop-menu {
    display: none;
  }
  .how-it-works-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .how-it-works-nav121 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-it-works-nav221 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-it-works-nav32 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-it-works-nav421 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-it-works-nav521 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-it-works-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .how-it-works-steps {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .how-it-works-container2 {
    flex-direction: column;
  }
  .how-it-works-step {
    padding: var(--dl-space-space-unit);
  }
  .how-it-works-step1 {
    padding: var(--dl-space-space-unit);
  }
  .how-it-works-step2 {
    padding: var(--dl-space-space-unit);
  }
  .how-it-works-step3 {
    padding: var(--dl-space-space-unit);
  }
  .how-it-works-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .how-it-works-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .how-it-works-container6 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .how-it-works-text22 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .how-it-works-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .how-it-works-mobile-menu1 {
    padding: 16px;
  }
  .how-it-works-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .how-it-works-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-it-works-hero-heading {
    height: 178px;
    padding-top: 91px;
  }
  .how-it-works-steps {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .how-it-works-container3 {
    align-items: center;
    flex-direction: column;
  }
  .how-it-works-text03 {
    color: var(--dl-color-gray-white);
    font-size: 0.75rem;
  }
  .how-it-works-text07 {
    color: var(--dl-color-gray-900);
    font-size: 0.75rem;
  }
  .how-it-works-container4 {
    width: 423px;
    align-items: center;
    flex-direction: column;
  }
  .how-it-works-text13 {
    color: var(--dl-color-gray-900);
    font-size: 0.75rem;
  }
  .how-it-works-footer {
    padding: var(--dl-space-space-unit);
  }
  .how-it-works-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .how-it-works-container6 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .how-it-works-text22 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
