.contact-us-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #121212;
}
.contact-us-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.contact-us-image {
  width: 200px;
  height: 67px;
  object-fit: cover;
}
.contact-us-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.contact-us-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact-us-nav12 {
  text-decoration: none;
}
.contact-us-nav22 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-nav42 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-nav52 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-login {
  font-family: "Chillax-Regular";
  margin-right: 32px;
  text-decoration: none;
}
.contact-us-register {
  font-family: "Chillax-Regular";
  text-decoration: none;
  background-color: #db0000;
}
.contact-us-burger-menu {
  display: none;
}
.contact-us-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.contact-us-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
.contact-us-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.contact-us-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-us-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.contact-us-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-nav121 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-nav221 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-nav32 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-nav421 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-nav521 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-us-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.contact-us-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.contact-us-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-us-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-us-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-us-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.contact-us-text {
  font-size: 3rem;
  text-align: center;
}
.contact-us-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.contact-us-text3 {
  color: var(--dl-color-custom-primary1);
}
.contact-us-btn-group {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.contact-us-container3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-us-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.contact-us-nav122 {
  text-decoration: none;
}
.contact-us-nav222 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-nav422 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-nav522 {
  margin-left: var(--dl-space-space-unit);
}
.contact-us-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.contact-us-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-icon-group1 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 991px) {
  .contact-us-hero1 {
    flex-direction: column;
  }
  .contact-us-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
}
@media(max-width: 767px) {
  .contact-us-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-us-desktop-menu {
    display: none;
  }
  .contact-us-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-us-nav121 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-us-nav221 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-us-nav32 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-us-nav421 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-us-nav521 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-us-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-us-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-us-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-us-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .contact-us-container4 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .contact-us-text4 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .contact-us-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .contact-us-mobile-menu1 {
    padding: 16px;
  }
  .contact-us-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .contact-us-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-us-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .contact-us-text {
    margin-top: var(--dl-space-space-unit);
  }
  .contact-us-btn-group {
    flex-direction: column;
  }
  .contact-us-footer {
    padding: var(--dl-space-space-unit);
  }
  .contact-us-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .contact-us-container4 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .contact-us-text4 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
